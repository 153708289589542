import React, { useEffect, useState, useRef, useCallback } from "react";
import { connect } from "react-redux";
import preval from "preval.macro";
import { store } from "../redux/store";
import { useTranslation } from "react-i18next";
import {
  getSetting,
  minutesToTime,
} from "../components/Utilities";
import {
  fetchTimeClocks,
  fetchSettings,
  setTime,
  clearTime,
  fetchTimeClockStatus,
  fetchTimeFrame,
  fetchExitReasons,
  setExitReason,
} from "../redux/actions/time";
import theme from "../Theme";
import { DateTime, Settings } from "luxon";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InfoButton from "../components/InfoButton";
import BreakButton from "../components/BreakButton";
import ExitButton from "../components/ExitButton";
import CloseButton from "../components/CloseButton";
import ReplayIcon from "@mui/icons-material/Replay";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import LocalCafeOutlinedIcon from "@mui/icons-material/LocalCafeOutlined";
import AdminMenu from "./AdminMenu";
import PersonInfo from "./PersonInfo";
import StartBreakDialog from "./StartBreakDialog";
import ExitDialog from "./ExitDialog";

const mapStateToProps = (state) => {
  return {
    time: state.time.Time,
    status: state.time.Status,
    settings: state.time.Settings,
    timeFrame: state.time.timeFrame,
    exitReasons: state.time.ExitReasons,
    exitReasonId: state.time.exitReasonId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTimeClocks: (values) => dispatch(fetchTimeClocks(values)),
    setTime: (values) => dispatch(setTime(values)),
    clearTime: () => dispatch(clearTime()),
    fetchSettings: (values) => dispatch(fetchSettings(values)),
    fetchTimeClockStatus: (values) =>
      dispatch(fetchTimeClockStatus({ idCode: values })),
    fetchTimeFrame: (values) => dispatch(fetchTimeFrame(values)),
    fetchExitReasons: (values) => dispatch(fetchExitReasons(values)),
    setExitReason: (values) => dispatch(setExitReason(values)),
  };
};

const TimeClock = ({
  time,
  status,
  settings,
  timeFrame,
  exitReasons,
  exitReasonId,
  setTime,
  clearTime,
  fetchTimeClocks,
  fetchSettings,
  fetchTimeClockStatus,
  fetchTimeFrame,
  fetchExitReasons,
  setExitReason,
}) => {
  const { t, i18n } = useTranslation();
  const timeRef = useRef();
  const [transponder, setTransponder] = useState("");
  const [displayTime, setDisplayTime] = useState(
    DateTime.now().toFormat("HH:mm:ss")
  );
  const [screensaver, showScreensaver] = useState(true);
  const [breakMode, setBreakMode] = useState(false);
  const [infoMode, setInfoMode] = useState(false);
  const [exitMode, setExitMode] = useState(false);
  const [idCode, setIdCode] = useState("");
  const [terminalName, setTerminalName] = useState("");
  const [showAdminMenu, setShowAdminMenu] = useState(false);
  const [showPersonInfo, setShowPersonInfo] = useState(false);
  const [askBreak, setAskBreak] = useState(false);
  const [askExit, setAskExit] = useState(false);
  const [isLocked, setLocked] = useState(false);
  const adminCode1 = getSetting("adminTransponder1");
  const adminCode2 = getSetting("adminTransponder2");
  const adminCode3 = getSetting("adminTransponder3");
  const logoName = "images/" + getSetting("timeClockLogoName");
  const logoAltName = getSetting("timeClockLogoAltName");
  // const screensaverText = getSetting("timeClockScreensaverText");
  const [screensaverText, setScreensaverText] = useState("");
  const counter = useRef(0);
  const statusCounter = useRef(0);
  const settingsCounter = useRef(0);
  const screensaverCounter = useRef(0);
  const language = useRef("DE");
  const buildTimestamp = preval`module.exports = new Date().getTime();`;
  const buildMonthDay =
    DateTime.fromMillis(buildTimestamp).toFormat("MMdd.HHmm");

  const buildYear = DateTime.fromMillis(buildTimestamp).year - 2022;
  const buildVersion = buildYear + "-" + buildMonthDay;

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const displaySeconds = useRef(0);
  const screensaverSeconds = useRef(0);
  const loadSettingsSeconds = useRef(0);
  const useScreenSaver = useRef(true);

  // For screensaver
  const moving = { width: 420, height: 160 };
  const posX = useRef(0);
  const posY = useRef(0);
  const moveRight = useRef(true);
  const moveDown = useRef(true);

  const setPosition = () => {
    const dot = document.querySelector("#dot");
    if (dot) {
      const screen = document.querySelector("#container");
      const increment = 1;
      if (moveRight.current) {
        posX.current += increment;
      }
      if (!moveRight.current) {
        posX.current -= increment;
      }
      if (moveDown.current) {
        posY.current += increment;
      }
      if (!moveDown.current) {
        posY.current -= increment;
      }
      dot.style.left = posX.current + "px";
      dot.style.top = posY.current + "px";
      // Border detection
      if (
        posX.current >= screen.offsetLeft + screen.offsetWidth - moving.width ||
        posX.current <= screen.offsetLeft
      ) {
        moveRight.current = !moveRight.current;
      }
      if (
        posY.current >=
          screen.offsetTop + screen.offsetHeight - moving.height ||
        posY.current <= screen.offsetTop
      ) {
        moveDown.current = !moveDown.current;
      }
    }
  };

  const setCounter = () => {
    counter.current++;
    if (counter.current > displaySeconds.current) {
      counter.current = 0;
      setBreakMode(false);
      setInfoMode(false);
      setExitMode(false);
      setExitReason(0);
      clearTime();
    }
  };

  const setSettingsCounter = () => {
    settingsCounter.current++;
    if (settingsCounter.current > loadSettingsSeconds.current) {
      settingsCounter.current = 0;
      fetchSettings({ component: "timeclocks", locationId: 1 });
      fetchExitReasons({
        locationId: status?.locationId,
      });
    }
  };

  const setStatusCounter = () => {
    statusCounter.current++;
    if (statusCounter.current > 30) {
      statusCounter.current = 0;
      fetchTimeClockStatus(localStorage.getItem("terminalIdCode"));
    }
  };

  const setScreensaverCounter = () => {
    if (useScreenSaver.current) {
      screensaverCounter.current++;
      if (screensaverCounter.current > screensaverSeconds.current) {
        screensaverCounter.current = 0;
        showScreensaver(true);
      }
    }
  };

  const handleLanguageChange = () => {
    if (language.current === "DE") {
      language.current = "EN";
      i18n.changeLanguage("en-US");
      Settings.defaultLocale = "en";
      localStorage.setItem("lang", "en-US");
    } else if (language.current === "EN") {
      language.current = "CZ";
      i18n.changeLanguage("cs-CZ");
      Settings.defaultLocale = "cs";
      localStorage.setItem("lang", "cs-CZ");
    } else {
      language.current = "DE";
      i18n.changeLanguage("de-DE");
      Settings.defaultLocale = "de";
      localStorage.setItem("lang", "de-DE");
    }
  };

  const handleScreensaverClick = () => {
    showScreensaver(false);
    screensaverCounter.current = 1;
  };

  const handleCloseAdminMenu = () => {
    setShowAdminMenu(false);
    setIdCode(localStorage.getItem("terminalIdCode"));
    setTerminalName(localStorage.getItem("terminalName"));
  };

  const handleClosePersonInfo = () => {
    setInfoMode(false);
    setShowPersonInfo(false);
    screensaverCounter.current = 1;
  };

  const handleCloseAskBreak = () => {
    store.dispatch({ type: "CLEAR_TIME_FRAME" });
    setAskBreak(false);
    setTransponder("");
    screensaverCounter.current = 1;
  };

  const handleCloseExitDialog = () => {
    store.dispatch({ type: "CLEAR_TIME_FRAME" });
    setAskExit(false);
    setTransponder("");
    screensaverCounter.current = 1;
  };

  const handleClose = () => {
    counter.current = 0;
    setBreakMode(false);
    setInfoMode(false);
    setExitMode(false);
    clearTime();
  };

  const submitData = () => {
    fetchTimeFrame({
      transponder: transponder,
      date: DateTime.now().toSQLDate(),
    });
    store.dispatch({ type: "SET_TAG", payload: transponder });
    if (
      transponder !== "" &&
      (transponder === adminCode1 ||
        transponder === adminCode2 ||
        transponder === adminCode3)
    ) {
      setShowAdminMenu(true);
    } else {
      if (infoMode) {
        setShowPersonInfo(true);
      }
    }
    showScreensaver(false);
    counter.current = 1;
    screensaverCounter.current = 1;
  };

  const handleFullScreen = () => {
    enterFullscreen(document.documentElement);
  };

  const handleReloadClick = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    window.location.reload(true);
  };

  const handleInfoClick = () => {
    store.dispatch({ type: "SET_RETURN_CODE", payload: "" });
    setTransponder("");
    setBreakMode(false);
    setExitMode(false);
    setInfoMode(true);
    counter.current = 1;
    screensaverCounter.current = 1;
  };

  const handleBreakClick = () => {
    store.dispatch({ type: "SET_RETURN_CODE", payload: "" });
    setInfoMode(false);
    setBreakMode(true);
    setExitMode(false);
    counter.current = 1;
    screensaverCounter.current = 1;
  };

  const handleExitClick = () => {
    setAskExit(true);
    setInfoMode(false);
    setBreakMode(false);
    counter.current = 1;
    screensaverCounter.current = 1;
  };

  const keyPress = useCallback(
    (e) => {
      const key = e.key;
      if (key !== "Shift") {
        if (transponder !== "" && (key === "*" || key === "Enter")) {
          submitData();
        } else {
          setTransponder(transponder + key);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transponder, setTransponder]
  );

  function enterFullscreen(element) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.msRequestFullscreen) {
      // for IE11 (remove June 15, 2022)
      element.msRequestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      // iOS Safari
      element.webkitRequestFullscreen();
    }
  }

  useEffect(() => {
    if (Object.keys(timeFrame).length !== 0) {
      const submitTime = DateTime.now().toFormat("HH:mm:ss");
      const submitMinutes =
        parseInt(DateTime.now().toFormat("HH")) * 60 +
        parseInt(DateTime.now().toFormat("mm"));
      const transmit = {
        transponder: transponder,
        date: DateTime.now().toSQLDate(),
        time: submitTime,
        minutes: submitMinutes,
        isBreak: breakMode ? "1" : "0",
        terminalId: localStorage.getItem("terminalIdCode"),
        exitReasonId: exitReasonId,
      };
      if (!showAdminMenu && !infoMode && !isLocked) {
        if (
          !breakMode &&
          exitReasonId === 0 &&
          timeFrame.askForBreak &&
          submitMinutes >= timeFrame.startMinutes &&
          submitMinutes <= timeFrame.endMinutes
        ) {
          setAskBreak(true);
        } else {
          setTime(transmit);
        }
      }
      setTransponder("");
    }
    // eslint-disable-next-line
  }, [timeFrame]);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  useEffect(() => {
    if (settings) {
      useScreenSaver.current = parseInt(getSetting("useScreenSaver")) === 1;
      displaySeconds.current = parseInt(getSetting("timeClockDisplaySeconds"));
      screensaverSeconds.current = parseInt(
        getSetting("timeClockScreensaverSeconds")
      );
      loadSettingsSeconds.current =
        parseInt(getSetting("loadSettingsMinutes")) * 60;
      fetchExitReasons({
        locationId: status?.locationId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  useEffect(() => {
    if (status) {
      setLocked(status.isLocked);
      setScreensaverText(status.screensaverText);
      i18n.changeLanguage(status.language);
      Settings.defaultLocale = status.language?.substring(0, 2);
      language.current = status.language?.substring(3, 5);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    fetchTimeClocks({ locationId: status.locationId });
    window.addEventListener("offline", function (e) {
      console.log("offline");
    });

    window.addEventListener("online", function (e) {
      console.log("online");
    });
    const storedLanguage = localStorage.getItem("lang");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      Settings.defaultLocale = storedLanguage.substring(0, 2);
      language.current = storedLanguage.substring(3, 5);
    } else {
      i18n.changeLanguage("de-DE");
      Settings.defaultLocale = "de";
      language.current = "DE";
    }

    setInterval(setPosition, 20);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  useEffect(() => {
    setIdCode(localStorage.getItem("terminalIdCode"));
    setTerminalName(localStorage.getItem("terminalName"));
    fetchTimeClockStatus(localStorage.getItem("terminalIdCode"));
    timeRef.current = setInterval(() => {
      setDisplayTime(DateTime.now().toFormat("HH:mm:ss"));
      setCounter();
      setSettingsCounter();
      setStatusCounter();
      setScreensaverCounter();
    }, 1000);
    return () => {
      clearInterval(timeRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setExitMode(exitReasonId !== 0);
  }, [exitReasonId]);

  return (
    <Box>
      {screensaver && useScreenSaver.current && (
        <>
          <Box
            id="container"
            component="div"
            sx={{
              width: "100vw",
              height: "100vh",
              position: "absolute",
              backgroundColor: "#000000D0",
              overflow: "hidden",
              zIndex: 50,
            }}
            onClick={handleScreensaverClick}
          ></Box>
          <Box
            id="dot"
            sx={{
              position: "absolute",
              fontSize: "2rem",
              color: "#FFFFFF",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: moving.width,
              height: moving.height,
              left: posX.current,
              top: posY.current,
              zIndex: 100,
            }}
            onClick={handleScreensaverClick}
          >
            <img alt={logoAltName} src={logoName} style={{ width: "10rem" }} />
            <Box sx={{ textAlign: "center" }}>{screensaverText}</Box>
          </Box>
        </>
      )}
      <Box sx={{ backgroundColor: "black", overflow: "hidden" }}>
        <Box
          sx={{
            fontSize: "2rem",
            height: "4rem",
            width: "100vw",
            display: "grid",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0px 3px 3px 1px rgba(0, 0, 0, 0.4)",
            backgroundColor: theme.palette.somura,
            color: "white",
            gridTemplateColumns: "4rem 7rem 1fr 3rem 4rem 4rem",
            gridTemplateRows: "4rem",
          }}
        >
          <Box
            sx={{
              gridColumn: "1",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
            onClick={handleReloadClick}
          >
            <ReplayIcon style={{ fontSize: "3rem" }} />
          </Box>
          {idCode !== null && (
            <Box
              sx={{
                gridColumn: "2 / 3",
                display: "flex",
                alignContent: "center",
                justifyContent: "flex-start",
                whiteSpace: "nowrap",
                paddingLeft: "4px",
              }}
            >
              {terminalName}
            </Box>
          )}
          <Box
            sx={{
              gridColumn: "3",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{
                height: "3.2rem",
                marginRight: "1rem",
              }}
              alt="Logo"
              src="/images/Somura-icon_wht_512.svg"
              width="40"
              height="40"
            />
            {t("Somura Time Tracking")}
          </Box>
          <Box
            sx={{
              gridColumn: "4",
              display: "flex",
              alignItems: "center",
            }}
          >
            {isOnline ? (
              <WifiIcon style={{ fontSize: "3rem" }} />
            ) : (
              <WifiOffIcon style={{ fontSize: "3rem" }} />
            )}
          </Box>
          <Box
            sx={{
              gridColumn: "5",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleLanguageChange}
          >
            {language.current}
          </Box>
          <Box
            sx={{
              gridColumn: "6",
              display: "flex",
              alignItems: "center",
            }}
            onClick={handleFullScreen}
          >
            <FullscreenIcon style={{ fontSize: "3rem" }} />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100vw",
            height: "calc(100vh - 8rem)",
            marginTop: "3rem",
            backgroundColor: "#000000",
            display: "grid",
            gridTemplateRows: "5rem 1fr 1fr 1fr 5rem",
            gridTemplateColumns: "5rem 1fr 1fr 1fr5rem ",
          }}
        >
          {isOnline === false ? (
            <Box
              sx={{
                width: "50%",
                margin: "12rem auto 0 auto",
                padding: "0 2rem 2rem 2rem",
                borderRadius: "10px",
                backgroundColor: theme.palette.red,
              }}
            >
              <Box
                sx={{
                  fontSize: "3rem",
                  color: "#FFFFFF",
                  textAlign: "center",
                }}
              >
                {t("Terminal is offline!")}
              </Box>
              <Box
                sx={{
                  fontSize: "2.53rem",
                  color: "#FFFFFF",
                  textAlign: "center",
                  marginTop: "-1rem",
                }}
              >
                {t(time.returnSubCode)}
              </Box>
            </Box>
          ) : idCode === null ? (
            <>
              <Box
                sx={{
                  width: "50%",
                  margin: "12rem auto 0 auto",
                  padding: "0 2rem 2rem 2rem",
                  borderRadius: "10px",
                  backgroundColor: theme.palette.red,
                }}
              >
                <Box
                  sx={{
                    fontSize: "3rem",
                    color: "#FFFFFF",
                    textAlign: "center",
                  }}
                >
                  {t("Terminal not registered")}
                </Box>
                <Box
                  sx={{
                    fontSize: "2.53rem",
                    color: "#FFFFFF",
                    textAlign: "center",
                    marginTop: "-1rem",
                  }}
                >
                  {t(time.returnSubCode)}
                </Box>
              </Box>
            </>
          ) : isLocked === true ? (
            <>
              <Box
                sx={{
                  width: "50%",
                  margin: "12rem auto 0 auto",
                  padding: "0 2rem 2rem 2rem",
                  borderRadius: "10px",
                  backgroundColor: theme.palette.red,
                }}
              >
                <Box
                  sx={{
                    fontSize: "3rem",
                    color: "#FFFFFF",
                    textAlign: "center",
                  }}
                >
                  {t("Terminal is locked!")}
                </Box>
                <Box
                  sx={{
                    fontSize: "2.53rem",
                    color: "#FFFFFF",
                    textAlign: "center",
                    marginTop: "-1rem",
                  }}
                >
                  {t(time.returnSubCode)}
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Grid
                container
                spacing={3}
                style={{ gridColumn: "1 / 6", height: "30%" }}
              >
                <Grid item xs={4}>
                  <Box
                    sx={{
                      color: "#FFFFFF",
                      paddingLeft: "4rem",
                      fontSize: "3rem",
                      fontWeight: "light",
                    }}
                  >
                    {DateTime.now().toLocaleString({
                      weekday: "long",
                    })}
                  </Box>
                  <Box
                    sx={{
                      color: "#FFFFFF",
                      paddingLeft: "4rem",
                      fontSize: "3rem",
                      fontWeight: "200",
                    }}
                  >
                    {DateTime.now().toFormat("DDD")}
                  </Box>
                </Grid>
                <Grid item xs={4} style={{ textAlignLast: "center" }}>
                  <InfoButton
                    onClick={handleInfoClick}
                    disabled={infoMode || breakMode || exitMode}
                  />
                  <BreakButton
                    disabled={infoMode || breakMode || exitMode}
                    onClick={handleBreakClick}
                  />
                  {exitReasons.length > 0 && (
                    <ExitButton
                      onClick={handleExitClick}
                      disabled={infoMode || breakMode || exitMode}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  <Box
                    sx={{
                      color: "#FFFFFF",
                      paddingRight: "1rem",
                      fontSize: "7rem",
                      fontWeight: "200",
                      textAlign: "left",
                      lineHeight: "8rem",
                    }}
                  >
                    {displayTime}
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                style={{ gridRow: 3, gridColumn: "1 / 6" }}
              >
                {time.lastName &&
                  counter.current > 0 &&
                  (time.returnCode === "Not recognized" ? (
                    <Box
                      sx={{
                        width: "50%",
                        margin: "5rem auto 0 auto",
                        padding: "0 2rem 0.5rem 2rem",
                        borderRadius: "10px",
                        backgroundColor: theme.palette.red,
                      }}
                    >
                      <Box
                        sx={{
                          fontSize: "5rem",
                          color: "#FFFFFF",
                          textAlign: "center",
                        }}
                      >
                        {t(time.returnCode)}
                      </Box>
                    </Box>
                  ) : (
                    // !askBreak && (
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          fontSize: "3rem",
                          color: "#FFFFFF",
                          textAlign: "center",
                        }}
                      >
                        {time.lastName + ", " + time.firstName}
                      </Box>
                      <Box
                        sx={{
                          fontSize: "2rem",
                          color: "#FFFFFF",
                          textAlign: "center",
                        }}
                      >
                        {time.personNumber}
                      </Box>
                      <Box
                        sx={{
                          width: "50%",
                          margin: "1rem auto 0 auto",
                          padding: "0 2rem",
                          borderRadius: "10px",
                          display: "grid",
                          gridTemplateRows: "9rem 2.5rem 4rem 4px",
                          gridTemplateColumns: "2rem 1fr 2rem",
                          backgroundColor:
                            time.returnCode === "Enter" ||
                            time.returnCode === "Enter - back from break" ||
                            time.returnCode === "Exit"
                              ? theme.palette.green
                              : time.returnCode === "Start break" ||
                                time.returnCode === "End break"
                              ? theme.palette.orange
                              : exitReasonId > 0
                              ? exitReasons.find((ER) => ER.id === exitReasonId)
                                  .backColor
                              : theme.palette.red,
                        }}
                      >
                        <Box sx={{ gridRow: "1", gridColumn: "3" }}>
                          <CloseButton
                            onClick={handleClose}
                            color={
                              exitReasonId > 0
                                ? exitReasons.find(
                                    (ER) => ER.id === exitReasonId
                                  ).fontColor
                                : "#FFFFFF"
                            }
                          />
                        </Box>
                        <Box
                          sx={{
                            fontSize: "5rem",
                            color:
                              exitReasonId > 0
                                ? exitReasons.find(
                                    (ER) => ER.id === exitReasonId
                                  ).fontColor
                                : "#FFFFFF",
                            textAlign: "center",
                            gridRow: "1",
                            gridColumn: "2",
                          }}
                        >
                          {exitReasonId > 0
                            ? time.returnCode
                            : t(time.returnCode)}

                          <Box
                            sx={{
                              fontSize: "2.5rem",
                              color: "#FFFFFF",
                              textAlign: "center",
                              marginTop: "-1rem",
                            }}
                          >
                            {t(time.returnSubCode)}
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            fontSize: "2rem",
                            color:
                              exitReasonId > 0
                                ? exitReasons.find(
                                    (ER) => ER.id === exitReasonId
                                  ).fontColor
                                : "#FFFFFF",
                            textAlign: "center",
                            gridRow: "2",
                            gridColumn: "2",
                          }}
                        >
                          {DateTime.fromSQL(time.date).toLocaleString(
                            DateTime.DATE_SHORT
                          ) +
                            " " +
                            time.time}
                        </Box>
                        <Box
                          sx={{
                            fontSize: "1.5rem",
                            color:
                              exitReasonId > 0
                                ? exitReasons.find(
                                    (ER) => ER.id === exitReasonId
                                  ).fontColor
                                : "#FFFFFF",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gridRow: "3",
                            gridColumn: "2",
                          }}
                        >
                          <AccessTimeOutlinedIcon sx={{ marginRight: "6px" }} />
                          {minutesToTime(time.workMinutes)}
                          <LocalCafeOutlinedIcon
                            sx={{ marginLeft: "2rem", marginRight: "6px" }}
                          />
                          {minutesToTime(time.breakMinutes)}
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                {!time.lastName && !screensaver && (
                  <Box
                    sx={{
                      fontSize: "2rem",
                      color: "#FFFFFF",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                      width: "75%",
                      height: moving.height,
                      margin: "5rem auto",
                    }}
                  >
                    <img
                      alt={logoAltName}
                      src={logoName}
                      style={{ width: "10rem" }}
                    />

                    <Box>{t("Please present transponder")}</Box>
                    {exitMode && (
                      <Box
                        sx={{
                          fontSize: "4rem",
                          color: exitReasons?.find(
                            (ER) => parseInt(ER.id) === parseInt(exitReasonId)
                          )?.backColor,
                        }}
                      >
                        {t("Exit") + ": " +
                          exitReasons?.find(
                            (ER) => parseInt(ER.id) === parseInt(exitReasonId)
                          )?.name
                        }
                      </Box>
                    )}
                    {breakMode && (
                      <Box sx={{ fontSize: "4rem", color: "#fb8c00" }}>
                        {t("Begin break")}
                      </Box>
                    )}
                    {infoMode && (
                      <Box
                        sx={{ fontSize: "4rem", color: "#5690F1" }}
                      >
                        {t("Show information")}
                      </Box>
                    )}
                  </Box>
                )}
              </Grid>
            </>
          )}
        </Box>
        <Box
          sx={{
            width: "100vw",
            height: "1rem",
            fontSize: "0.7rem",
            lineHeight: "0.5rem",
            backgroundColor: "#000000",
            color: "#ffffff",
            position: "fixed",
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
          }}
        >
          <Box sx={{ gridColumn: "2", textAlign: "center" }}>
            &copy; 2024: NETitwork GmbH
          </Box>
          <Box
            sx={{
              gridColumn: "3",
              textAlign: "right",
              marginRight: "4px",
              color: "grey",
            }}
          >
            {buildVersion}
          </Box>
        </Box>
      </Box>
      {showAdminMenu && <AdminMenu closeAdminMenu={handleCloseAdminMenu} />}
      {showPersonInfo && <PersonInfo closePersonInfo={handleClosePersonInfo} />}
      {askBreak && <StartBreakDialog closeStartBreak={handleCloseAskBreak} />}
      {askExit && <ExitDialog closeExitDialog={handleCloseExitDialog} />}
    </Box>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeClock);
